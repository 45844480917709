import React, { useRef, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link as GatsbyLink } from "gatsby";
import { useLocation } from "@reach/router";
import "twin.macro";
import {
  HomeIcon,
  Mail,
  Path5,
  Photos,
  MenuIcon,
  MenuIconBlack,
  LogoMain,
  FacebookLogo,
} from "../../static/SVGIcons";
import {
  Button,
  Box,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Image,
  HStack,
  List,
  ListItem,
  Text,
  useDisclosure,
  useBreakpointValue,
  Link,
} from "@chakra-ui/react";

export default function Header() {
  const { pathname } = useLocation();
  console.log(pathname);
  const hiddenMdUp = useBreakpointValue({ base: "block", md: "none" });
  const hiddenMdDown = useBreakpointValue({ base: "none", md: "block" });
  // const { site } = useStaticQuery(query);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  const [linkState, setLinkState] = useState("header");
  const navigationLinks = [
    { navIcon: HomeIcon, navText: "Home", link: "header" },
    { navIcon: Photos, navText: "Das Sortiment", link: "mittagstisch" },
    { navIcon: Path5, navText: "Öffnungszeiten", link: "footer-main" },
    { navIcon: Mail, navText: "Kontakt", link: "footer-main" },
  ];

  return (
    <header id="header">
      <Box display={hiddenMdUp}>
        <Button
          ref={btnRef}
          onClick={onOpen}
          position="absolute"
          zIndex={1}
          left="10px"
          top="40px"
          bg="none"
          boxShadow="none"
          _focus={{ outline: "none" }}
        >
          <img
            tw="w-6 h-6"
            src={
              pathname === "/impressum" || pathname === "/datenschutz"
                ? MenuIconBlack
                : MenuIcon
            }
          />
        </Button>
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          preserveScrollBarGap={true}
          finalFocusRef={`#${linkState}`}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader tw="mt-8 mx-auto">
              <GatsbyLink
                onClick={onClose}
                to="/"
                _focus={{ boxShadow: "none" }}
              >
                <Image src={LogoMain} alt="logo" w="150px" />
              </GatsbyLink>
            </DrawerHeader>
            <DrawerBody overflowX="hidden" overflowY="scroll">
              <List mt={8} textTransform="uppercase">
                {navigationLinks.map((navLink, navLinkIndex) => (
                  <ListItem
                    key={`mobileNav-${navLinkIndex}`}
                    tw="flex items-center mb-8 ml-8 w-full"
                  >
                    <Image mr={8} w="16px" h="16px" src={navLink.navIcon} />
                    <GatsbyLink
                      onClick={() => {
                        setLinkState(navLink.link);
                        onClose();
                      }}
                      to={`/#${navLink.link}`}
                    >
                      <Text
                        _focus={{ boxShadow: "none" }}
                        _hover={{ textDecoration: "none" }}
                      >
                        {navLink.navText}
                      </Text>
                    </GatsbyLink>
                  </ListItem>
                ))}
              </List>
            </DrawerBody>
            <DrawerFooter
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              bgColor="#353535"
              p={6}
              w="100%"
              h="100px"
            >
              <HStack
                color="#A6ADB4"
                fontWeight="bold"
                spacing="24px"
                mt={{ base: 4, md: 0 }}
              >
                <Link href="https://www.facebook.com/pages/category/Grocery-Store/Casa-italiana-114893663540029/">
                  <Image src={FacebookLogo} alt="facebook logo" w="15px" />
                </Link>
                <GatsbyLink to="/impressum">Impressum</GatsbyLink>
                <GatsbyLink to="/datenschutz">Datenschutz</GatsbyLink>
              </HStack>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </Box>
      <Box
        display={hiddenMdDown}
        h="80px"
        w="100%"
        bgColor="#F6F8F9"
        position="fixed"
        top={0}
        px={14}
        zIndex={10}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          maxWidth="1440px"
          mx="auto"
          h="100%"
        >
          <Box w="150px">
            <GatsbyLink to="/" _focus={{ boxShadow: "none" }}>
              <StaticImage
                src="../../static/logoMain.svg"
                placeholder="blurred"
                alt="logo"
              />
            </GatsbyLink>
          </Box>
          <HStack spacing="16px" textTransform="uppercase" fontWeight="bold">
            {navigationLinks.map((navLink, NavLinkIndex) => (
              <GatsbyLink
                to={`/#${navLink.link}`}
                key={`navLink-${NavLinkIndex}`}
              >
                <Text
                  cursor="pointer"
                  _hover={{ color: "#DD413B" }}
                  _focus={{ boxShadow: "none" }}
                >
                  {navLink.navText}
                </Text>
              </GatsbyLink>
            ))}
          </HStack>
        </Box>
      </Box>
    </header>
  );
}
