import React from "react";
import { Box, Image, HStack, useBreakpointValue, Link } from "@chakra-ui/react";
import { Link as GatsbyLink } from "gatsby";
import { LogoMain, FacebookLogo } from "../../static/SVGIcons";

export default function Footer() {
  const hiddenMdUp = useBreakpointValue({ base: "block", md: "none" });
  const hiddenMdDown = useBreakpointValue({ base: "none", md: "block" });
  return (
    <footer id="footer">
      <Box bgColor="#353535">
        <Box
          display="flex"
          justifyContent={{ base: "center", md: "space-between" }}
          alignItems="center"
          flexWrap="wrap"
          py="60px"
          px="60px"
          maxWidth="1440px"
          mx="auto"
          color="#A6ADB4"
          fontSize={{ base: "13px", md: "25px" }}
          fontWeight="bold"
        >
          <Box display={hiddenMdUp}>
            <Link href="https://www.facebook.com/pages/category/Grocery-Store/Casa-italiana-114893663540029/">
              <Image src={FacebookLogo} alt="facebook logo" w="18px" />
            </Link>
          </Box>
          <Box w={{ base: "100%", md: "30%" }} my={{ base: 6, md: 0 }}>
            <GatsbyLink to="/">
              <Image
                src={LogoMain}
                alt="footer logo"
                // maxWidth="300px"
                mx="auto"
              />
            </GatsbyLink>
          </Box>
          <HStack spacing="24px">
            <GatsbyLink to="/impressum">Impressum</GatsbyLink>
            <GatsbyLink to="/datenschutz">Datenschutz</GatsbyLink>
            <Link
              display={hiddenMdDown}
              href="https://www.facebook.com/pages/category/Grocery-Store/Casa-italiana-114893663540029/"
            >
              <Image src={FacebookLogo} alt="facebook logo" w="18px" />
            </Link>
          </HStack>
        </Box>
      </Box>
    </footer>
  );
}
